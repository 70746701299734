import { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { flushSync } from "react-dom"
import Check from "@mui/icons-material/Check"
import PersonIcon from "@mui/icons-material/Person"
import OrderrIcon from "@mui/icons-material/ShoppingCart"
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material"
import { Divider, ListItemIcon, Tooltip } from "@mui/material"
import { Badge, Button, Menu, MenuItem, Paper, Switch } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import {
  fetchFileList,
  unlockCaseApi,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { resetOrder } from "@/core/app/slices/order/orderSlice"
import { RootState } from "@/core/app/store"
import { setOpenCollisionCheck } from "@/UDTreat/udTreatSlice"
import {
  attachmentModule,
  initialfinal,
  preview,
  stageControl,
  toothMovement,
  treatView,
  viewControlInTreatment,
  viewEdit,
  wasmModule,
} from "@/gluelayer"
import { caseManagement } from "@/gluelayer"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import Person from "@mui/icons-material/Person"
import { orderButtonPopUpTmp } from "../bottom/Toggle/column"
import {
  setCurArch,
  setCurCaseMode,
  setCurLeftPage,
  setCurRightPage,
  setIsCreateNewRefinement,
  setSmartRxShow,
  setStageShowType,
  setSwitchViewEdit,
  setUcloud1_1,
  setWorkflowMode,
  setOpenBiteCheck,
} from "../udTreatSlice"
import CloseIcon from "@mui/icons-material/Close"
import {
  BackIcon,
  FrontIcon,
  LeftIcon,
  LowerIcon,
  RightIcon,
  SplitIcon,
  UpperIcon,
} from "./TopToolBarIcon"
import {
  BiteCheckIcon,
  CollisionIcon,
  GridIcon,
  InitFinalIcon,
  LiteModeIcon,
  StatusIcon,
  SuperImposeIcon,
  WorkflowIcon,
} from "./TopToolBarIcon"
import { ZoomBar } from "./ZoomBar"

import { PatientNameID } from "./PatientNameID"
import { orderAbleTmp } from "@/modules/Clinical/Treat/column"
import { FILEZIPS } from "@/core/app/slices/clinical/clinicalSlice"
import { UText } from "@/components/index"
import { useTranslation } from "react-i18next"
import MessageIcon from "@mui/icons-material/Message"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/components/index"
import { getResultByPhotoJson } from "@/modules/Records/column"
import { setUassistRxShow } from "../udTreatSlice"
import { CreateRefinement } from "@/modules/Records/CreateRefinement"
import { checkIsThereASpecifiedPhoto } from "../left/utils"

import NewUpperIcon from "../assets/toptoolbar_1_0/upper.svg"
import NewLowerIcon from "../assets/toptoolbar_1_0/lower.svg"
import NewRightIcon from "../assets/toptoolbar_1_0/right.svg"
import NewLeftIcon from "../assets/toptoolbar_1_0/left.svg"
import NewFrontIcon from "../assets/toptoolbar_1_0/front.svg"
import NewBackIcon from "../assets/toptoolbar_1_0/back.svg"
import NewSplitIcon from "../assets/toptoolbar_1_0/split.svg"
import NewInitialFinalIcon from "../assets/toptoolbar_1_0/initialfinal.svg"
import NewGridIcon from "../assets/toptoolbar_1_0/grid.svg"
import NewLightmodeIcon from "../assets/toptoolbar_1_0/colored.svg"
import NewIPRIcon from "../assets/toptoolbar_1_0/ic_ipr.svg"
import NewClickedIPRIcon from "../assets/toptoolbar_1_0/clicked_ipr.svg"
import NewSuperimpositionIcon from "../assets/toptoolbar_1_0/ic_superimposition.svg"
import NewClickedSuperimpositionIcon from "../assets/toptoolbar_1_0/clicked_superimposition.svg"
import NewOccIcon from "../assets/toptoolbar_1_0/ic_occlusogram.svg"
import NewClickedOccIcon from "../assets/toptoolbar_1_0/clicked_occlusogram.svg"

import { ReactSVG } from "react-svg"
import {
  resetPrescriptionSchema,
  resetRxRefinementFormAdd,
  resetRxRefinementFormEdit,
  resetSchema,
} from "@/core/app/slices/uassist/formSlice"
import { hasFileHistoryChanged } from "@/modules/Patient/PatientList/util/commonUtil"
import { CaseUpdatedModal } from "../components/caseUpdatedModal"
import { setData } from "@/core/app/slices/case/caseSlice"
export function TopToolBar({
  setLockType,
  setOpenLockPopup,
  setisClickOrder,
  setispopupAfterOrder,
  switchEditCallBack,
  isEdit,
  ispopup,
  orderClick,
}) {
  const { t } = useTranslation("common")
  const theme = useTheme()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    hasUploadSTLs,
    workflowMode,
    curCaseMode,
    smartRxShow,
    curArch,
    ucloud1_1,
    isCasePreview,
    isNewCaseHasUpload,
    switchViewEdit,
    planList,
    stageDataLen,
    isCreateNewRefinement,
    openHistorySplitView,
    widthAll,
    curRightPage,
    currentTreatmentPlan,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseDisposition, upperType, lowerType, uassist_progress } = caseDetail
  console.log("999caseDisposition", caseDisposition)
  const [curStatusString, setCurStatusString] = useState("")
  // tools button status (selected or not)

  const { openCollisionCheck, openBiteCheck, uassistRxShow } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const [openInitialFinal, setOpenInitialFinal] = useState<boolean>(false)
  const [openSuperimpos, setOpenSuperimpos] = useState<boolean>(false)
  const [openGrid, setOpenGrid] = useState<boolean>(false)
  const [openLiteMode, setOpenLiteMode] = useState<boolean>(false)
  const [openUassist, setOpenUassist] = useState<boolean>(false)
  const [isDeletePlan, setisDeletePlan] = useState<boolean>(false)
  const [openRefDialog, setOpenRefDialog] = useState<boolean>(false)
  const [isStartRefinement, setisStartRefinement] = useState<boolean>(false)
  const [showCaseUpdatedModal, setShowcaseUpdatedModal] = useState(false)

  // check ipr before go to order
  const [checkIPRobj, setCheckIPRobj] = useState<{
    show: boolean
    showDetail: boolean
    detail: string
  }>({ show: false, showDetail: false, detail: "" })

  const location = useLocation()
  const { fileHistory } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const {
    patientData: { firstName = "", lastName = "" },
  } = useAppSelector((state: RootState) => {
    return state.patientService
  })

  const refineObj1 = isCreateNewRefinement
    ? { position: "absolute", left: "120px" }
    : {}
  const refineObj2 = isCreateNewRefinement
    ? { position: "absolute", left: "460px" }
    : {}

  const newCaseUDPlanStatus = [
    CASE_STATUS.DEFAULT,
    CASE_STATUS.COMPLETE_PATIENT_RECORDS,
    CASE_STATUS.PRESETUP_IN_PROGRESS,
    CASE_STATUS.FILL_OUT_RX_FORM,
    CASE_STATUS.SUBMIT_TO_UASSIST,
    CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
    CASE_STATUS.ATTENTION_NEEDED,
    CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
  ]
  const uDModiStatus = [
    CASE_STATUS.REVIEW_PLAN,
    CASE_STATUS.REVIEW_MODIFICATION,
  ]
  const uDViewStatus = [
    CASE_STATUS.SUBMITTED_TO_UASSIST,
    CASE_STATUS.MODIFICATION_REQUESTED,
    CASE_STATUS.PLANNING_IN_PROGRESS,
    CASE_STATUS.ORDER_SUBMITTED,
    CASE_STATUS.IN_PRODUCTION,
    CASE_STATUS.SHIPPED,
    CASE_STATUS.TREATMENT_IN_PROGRESS,
    CASE_STATUS.DELIVERED,
    CASE_STATUS.ARCHIVED,
    CASE_STATUS.CANCELLED,
  ]

  function getCaseMode(caseDisposition: string) {
    let caseMode = "NewCase"
    if (newCaseUDPlanStatus.includes(caseDisposition)) {
      if (isCasePreview) {
        caseMode = "NewCase"
      } else if (!ucloud1_1) {
        // use AI plan
        caseMode = "UDPlan"
      } else {
        caseMode = "NewCase"
      }
    } else if (uDModiStatus.includes(caseDisposition)) {
      caseMode = "UDModi"
    } else if (uDViewStatus.includes(caseDisposition)) {
      caseMode = "UDView"
    } else {
      caseMode = "UDView"
    }
    return caseMode
  }

  useEffect(() => {
    dispatch(setCurArch("all"))
  }, [])

  useEffect(() => {
    const caseDisposition = caseDetail.caseDisposition
    console.log("Current caseDisposition" + caseDisposition)
    dispatch(setCurCaseMode(getCaseMode(caseDisposition)))
    setCurStatusString(caseDisposition)
  }, [caseDetail, ucloud1_1, isCasePreview])

  useEffect(() => {
    if (!wasmModule.isInit) {
      if (preview.isInitPreview()) {
        if (curArch === "all") preview.changeArchMode("both")
        else if (curArch === "up") preview.changeArchMode("up")
        else if (curArch === "low") preview.changeArchMode("low")
      }
      return
    }
    if (curArch === "all") viewControlInTreatment.showArchMode("all")
    else if (curArch === "up") viewControlInTreatment.showArchMode("up")
    else if (curArch === "low") viewControlInTreatment.showArchMode("low")
  }, [curArch])
  const onClickUpperLower = (upper: boolean) => {
    const nextArch = upper
      ? curArch === "up"
        ? "all"
        : "up"
      : curArch === "low"
      ? "all"
      : "low"
    dispatch(setCurArch(nextArch))
  }

  useEffect(() => {
    if (switchViewEdit) {
      // console.log("🚀 ~ useEffect ~ location:", location)
      // 检查 hash 是否以 #uassit 结尾
      if (location.hash === "#uassist") {
        clickUassist()
      }
    }
  }, [switchViewEdit])

  useEffect(() => {
    if (
      curRightPage === "UDAux" ||
      curRightPage === "fastsmart" ||
      curRightPage === "finetune"
    ) {
      setOpenInitialFinal(false)
    }
  }, [curRightPage])

  useEffect(() => {
    if (!wasmModule.isInit) return
    viewControlInTreatment.setSpacecheckVisibility(
      openCollisionCheck && !openHistorySplitView,
    )
    viewControlInTreatment.setOcclusionVisibility(
      openBiteCheck && !openHistorySplitView,
    )
    viewControlInTreatment.setSuperimposeVisibility(
      openSuperimpos && !openHistorySplitView,
    )
    initialfinal.setInitialFinalEnabled(
      openInitialFinal && !openHistorySplitView && !isCreateNewRefinement,
    )

    //when open initial and final, hide the stage bar
    //close initial and final, show the stage bar
    if (openInitialFinal && !openHistorySplitView) {
      dispatch(setStageShowType(""))
      attachmentModule.openAttachmentModule(false)
    } else if (!openInitialFinal && !openHistorySplitView) {
      dispatch(setStageShowType("normal"))
      attachmentModule.openAttachmentModule(true)
    }
  }, [
    openCollisionCheck,
    openBiteCheck,
    openSuperimpos,
    openInitialFinal,
    openHistorySplitView,
  ])

  // Need to control grid switches individually
  useEffect(() => {
    if (!wasmModule.isInit) return
    viewControlInTreatment.setGridVisibility(openGrid && !openHistorySplitView)
  }, [openGrid, openHistorySplitView])

  useEffect(() => {
    if (!wasmModule.isInit) return
    viewControlInTreatment.setAttachmentVisibility(!openLiteMode)
    viewControlInTreatment.specialToothColorToggle(!openLiteMode)
    viewControlInTreatment.hoverToothToggle(
      !openLiteMode && !openHistorySplitView,
    )
  }, [openLiteMode, openHistorySplitView, currentTreatmentPlan])

  const NewViewButtons = () => {
    const viewButtons = [
      {
        title: "Right",
        value: "right",
        disabled: false,
        icon: <ReactSVG src={NewRightIcon}></ReactSVG>,
      },
      {
        title: "Front",
        value: "front",
        disabled: false,
        icon: <ReactSVG src={NewFrontIcon}></ReactSVG>,
      },
      {
        title: "Back",
        value: "back",
        disabled: false,
        icon: <ReactSVG src={NewBackIcon}></ReactSVG>,
      },
      {
        title: "Left",
        value: "left",
        disabled: false,
        icon: <ReactSVG src={NewLeftIcon}></ReactSVG>,
      },
      {
        title: "Split",
        value: "split",
        disabled: false,
        icon: <ReactSVG src={NewSplitIcon}></ReactSVG>,
      },
    ]
    const handleClick = (view) => {
      if (view === "split") {
        setOpenInitialFinal(false)
        setOpenGrid(false)
      }
      viewControlInTreatment.setViewType(view)
    }
    return (
      <>
        {viewButtons.map(({ title, value, disabled, icon }, index) => {
          if (value === "split" && isCreateNewRefinement) {
            return null
          }
          if (value === "split" && openHistorySplitView) {
            return null
          }
          return (
            <Tooltip key={index} title={title} placement="bottom">
              <ToggleButton
                sx={{
                  paddingBottom: 1,
                  border: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    borderRadius: "8px",
                  },
                  "&.Mui-selected": {
                    borderRadius: "8px",
                  },
                }}
                value={value}
                size="large"
                onClick={() => handleClick(value)}
              >
                {icon}
              </ToggleButton>
            </Tooltip>
          )
        })}
      </>
    )
  }

  const onClickSaveCase = (e) => {
    let path = -1
    const from = searchParams.get("from")
    if (from === "bundle" || location.hash === "#uassist") {
      console.log("location :", location.hash)
      path = "/patients"
    }
    navigate(path)
  }

  const NewToolButtons = () => {
    const toolButtons = [
      {
        title: "Collision Check",
        key: "collision",
        disabled: false,
        selected: openCollisionCheck,
        icon: (
          <ReactSVG
            src={NewIPRIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              const path1 = svg.getElementsByTagName("path")[1]
              const line = svg.getElementsByTagName("line")[0]
              if (openCollisionCheck) {
                path?.setAttribute("stroke", "#215ECD")
                path1?.setAttribute("stroke", "#215ECD")
                line?.setAttribute("stroke", "#215ECD")
              } else {
                path?.setAttribute("stroke", "#707070")
                path1?.setAttribute("stroke", "#707070")
                line?.setAttribute("stroke", "#707070")
              }
            }}
          ></ReactSVG>
        ),
      },
      {
        title: "Bite check",
        key: "bitecheck",
        disabled: false,
        selected: openBiteCheck,
        icon: (
          <ReactSVG
            src={NewOccIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              const path1 = svg.getElementsByTagName("path")[1]
              const path2 = svg.getElementsByTagName("path")[2]
              const rect = svg.getElementsByTagName("rect")[0]
              if (openBiteCheck) {
                path?.setAttribute("stroke", "#215ECD")
                path1?.setAttribute("stroke", "#215ECD")
                path2?.setAttribute("fill", "#215ECD")
                rect?.setAttribute("fill", "#215ECD")
              } else {
                path?.setAttribute("stroke", "#707070")
                path1?.setAttribute("stroke", "#707070")
                path2?.setAttribute("fill", "#707070")
                rect?.setAttribute("fill", "#215ECD")
              }
            }}
          ></ReactSVG>
        ),
      },
      {
        title: "Superimpose",
        key: "superimpose",
        disabled: false,
        selected: openSuperimpos,
        icon: (
          <ReactSVG
            src={NewSuperimpositionIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              const path1 = svg.getElementsByTagName("path")[1]
              if (openSuperimpos) {
                path?.setAttribute("stroke", "#215ECD")
                path1?.setAttribute("stroke", "#215ECD")
              } else {
                path?.setAttribute("stroke", "#707070")
                path1?.setAttribute("stroke", "#707070")
              }
            }}
          ></ReactSVG>
        ),
      },
      {
        title: "Initial Final",
        key: "initialfinal",
        disabled: false,
        selected: openInitialFinal,
        icon: (
          <ReactSVG
            src={NewInitialFinalIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              if (openInitialFinal) {
                path?.setAttribute("fill", "#215ECD")
                path?.setAttribute("fill-opacity", "1")
              } else {
                path?.setAttribute("fill", "black")
                path?.setAttribute("fill-opacity", "0.56")
              }
            }}
          ></ReactSVG>
        ),
      },
      {
        title: "Grid",
        key: "grid",
        disabled: false,
        selected: openGrid,
        icon: (
          <ReactSVG
            src={NewGridIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              if (openGrid) {
                path?.setAttribute("fill", "#215ECD")
                path?.setAttribute("fill-opacity", "1")
              } else {
                path?.setAttribute("fill", "black")
                path?.setAttribute("fill-opacity", "0.56")
              }
            }}
          ></ReactSVG>
        ),
      },

      {
        title: "Show or Hide",
        key: "litemode",
        disabled: false,
        selected: openLiteMode,
        icon: (
          <ReactSVG
            src={NewLightmodeIcon}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              if (openLiteMode) {
                path?.setAttribute("fill", "#215ECD")
                path?.setAttribute("fill-opacity", "1")
              } else {
                path?.setAttribute("fill", "black")
                path?.setAttribute("fill-opacity", "0.56")
              }
            }}
          ></ReactSVG>
        ),
      },
    ]

    const handleCollisionCheck = () => {
      const newState = !openCollisionCheck
      console.info("TopToolBar - Current collision check state: " + openCollisionCheck)
      console.info("TopToolBar - Setting collision check to: " + newState)
      dispatch(setOpenCollisionCheck(newState))
      wasmModule.wrapInstance.ShowCollision(newState)
    }

    useEffect(() => {
      console.info("TopToolBar - openCollisionCheck changed to: " + openCollisionCheck)
    }, [openCollisionCheck])

    const handleClick = (toolName) => {
      switch (toolName) {
        case "collision":
          if (!openCollisionCheck) setOpenInitialFinal(false)
          dispatch(setOpenCollisionCheck(!openCollisionCheck))
          viewControlInTreatment.setSpacecheckVisibility(!openCollisionCheck)
          break
        case "bitecheck":
          if (!openBiteCheck) {
            setOpenInitialFinal(false)
            toothMovement.switchModuleFastSmartCircle(false)
            toothMovement.switchModuleFineTuneCircle(false)
          }
          dispatch(setOpenBiteCheck(!openBiteCheck))
          break
        case "superimpose":
          if (!openSuperimpos) setOpenInitialFinal(false)
          setOpenSuperimpos(!openSuperimpos)
          break
        case "initialfinal":
          if (!openInitialFinal) {
            setOpenSuperimpos(false)
            dispatch(setOpenBiteCheck(false))
          }
          setOpenInitialFinal(!openInitialFinal)
          break
        case "grid":
          if (!openGrid) viewControlInTreatment.setViewType("front")
          setOpenGrid(!openGrid)
          break
        case "litemode":
          if (!openLiteMode) setOpenInitialFinal(false)
          setOpenLiteMode(!openLiteMode)
          break
      }
    }

    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setSpacecheckVisibility(
        openCollisionCheck && !openHistorySplitView,
      )
      viewControlInTreatment.setOcclusionVisibility(
        openBiteCheck && !openHistorySplitView,
      )
      viewControlInTreatment.setSuperimposeVisibility(
        openSuperimpos && !openHistorySplitView,
      )
      initialfinal.setInitialFinalEnabled(
        openInitialFinal && !openHistorySplitView && !isCreateNewRefinement,
      )

      //when open initial and final, hide the stage bar
      //close initial and final, show the stage bar
      if (openInitialFinal && !openHistorySplitView) {
        dispatch(setStageShowType(""))
      } else if (!openInitialFinal && !openHistorySplitView) {
        dispatch(setStageShowType("normal"))
      }
    }, [
      openCollisionCheck,
      openBiteCheck,
      openSuperimpos,
      openInitialFinal,
      openHistorySplitView,
    ])

    // Need to control grid switches individually
    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setGridVisibility(
        openGrid && !openHistorySplitView,
      )
    }, [openGrid, openHistorySplitView])

    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setAttachmentVisibility(!openLiteMode)
      viewControlInTreatment.specialToothColorToggle(!openLiteMode)
      viewControlInTreatment.hoverToothToggle(
        !openLiteMode && !openHistorySplitView,
      )
    }, [openLiteMode, openHistorySplitView])

    return (
      <>
        {toolButtons.map(({ title, key, disabled, icon, selected }, index) => {
          return (
            <Tooltip
              style={{ backgroundColor: selected ? "lightgray" : "" }}
              key={index}
              title={title}
              placement="bottom"
            >
              <ToggleButton
                sx={{
                  paddingBottom: 1,
                  border: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    borderRadius: "8px",
                  },
                  "&.Mui-selected": {
                    borderRadius: "8px",
                  },
                }}
                value={key}
                size="large"
                onClick={() => handleClick(key)}
              >
                {icon}
              </ToggleButton>
            </Tooltip>
          )
        })}
      </>
    )
  }

  const checkShowUpperLowerIcons = () => {
    if (curCaseMode === "NewCase") {
      if (hasUploadSTLs[0] && hasUploadSTLs[1]) return true
      else return false
    } else return true
  }

  const CaseModeBtn = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleSelect = (e: any, statusType: string) => {
      dispatch(setUcloud1_1(statusType === "ucloud1_1"))
      dispatch(setCurCaseMode(statusType))
      // dispatch(setSwitchViewEdit(statusType === "WeDesign"))
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <Tooltip title="Case Mode" placement="bottom">
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <StatusIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleSelect(e, "ucloud1_1")}>
            ucloud1_1
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "NewCase")}>
            New Case
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDPlan")}>
            uDesign
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDModi")}>
            Reivew & Modi
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDView")}>
            View Only
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "WeDesign")}>
            WeDesign
          </MenuItem>
          <Divider />
          <MenuItem>Case_status: {curStatusString}</MenuItem>
        </Menu>
      </>
    )
  }

  const WorkflowBtn = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleSelect = (e: any, caseType: string) => {
      dispatch(setWorkflowMode(caseType))
    }
    const handleClose = () => {
      dispatch(setWorkflowMode("aidesign"))
      setAnchorEl(null)
    }
    return (
      <>
        {/*
        <Tooltip title="Workflow" placement="bottom">
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <WorkflowIcon />
          </IconButton>
        </Tooltip>*/}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleSelect(e, "aidesign")}>
            AI Design
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "smartrx")}>
            Smart Rx
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "retainerrx")}>
            Retainer Rx
          </MenuItem>
        </Menu>
      </>
    )
  }

  // click uassit button will check photo first
  const { photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const [noPhotoObj, setNophotoObj] = useState<{ open: boolean; info: string }>(
    { open: false, info: "" },
  )
  const [resetCompleted, setResetCompleted] = useState(false)
  // click uassist button function
  const handleUassistClick = () => {
    dispatch(resetSchema())
    dispatch(resetPrescriptionSchema())
    dispatch(resetRxRefinementFormEdit())
    dispatch(resetRxRefinementFormAdd())
    dispatch(setSmartRxShow(false))
    dispatch(setCurLeftPage(""))
    dispatch(setUassistRxShow(false))
    // After dispatches, update the state flag
    setResetCompleted(true)
  }
  useEffect(() => {
    if (resetCompleted) {
      clickUassist()
      // Reset flag to prevent repeated calls if needed
      setResetCompleted(false)
    }
  }, [resetCompleted])  // Effect will run once the flag changes
  const clickUassist = () => {
    const { hasAnyphotos, hasPhotos, hasXrays } =
      getResultByPhotoJson(photoTypeJson)
    const { normalPosition, xrayPosition } = checkIsThereASpecifiedPhoto(
      photoTypeJson || {},
    )
    if (uassist_progress === "READY" && planList.length > 1) {
      setisDeletePlan(true)
      return
    }

    if (
      uassist_progress === "READY" &&
      stageControl.getMaxStageNodeIndex() > 0
    ) {
      setOpenUassist(true)
      return
    }

    if (
      uassist_progress === "READY" &&
      !(stageControl.getMaxStageNodeIndex() > 0)
    ) {
      dispatch(setUassistRxShow(true))

      if (!normalPosition && !xrayPosition) {
        dispatch(setCurLeftPage("records"))
      }
      return
    }
    if (uassist_progress === "REFINEMENT_READY") {
      if (planList.length > 1) {
        setisDeletePlan(true)
      } else {
        setisStartRefinement(true)
      }
      return
    }
  }
  const showRefinementPage = () => {
    setOpenRefDialog(true)
    treatView.startSplitScreenToCreateRefinement()
    dispatch(setIsCreateNewRefinement(true))
    dispatch(setStageShowType("compare"))
    setOpenUassist(false)
  }

  return (
    <Paper
      sx={{
        left: 16,
        top: 16,
        right: 16,
        height: "58px",
        backgroundColor: "transparent",
        justifyContent: "center",
        position: "absolute",
      }}
      elevation={0}
    >
      <CaseUpdatedModal
        showModal={showCaseUpdatedModal}
        setShowModal={setShowcaseUpdatedModal}
      />
      <Stack
        direction="row"
        sx={{ height: "100%" }}
        alignItems={"center"}
        spacing={1}
      >
        <PatientNameID
          firstName={firstName}
          lastName={lastName}
          caseId={caseId}
          patientId={patientId}
          creatDate={
            caseDetail.createDate
              ? `${caseDetail.createDate.substring(
                  5,
                  7,
                )}/${caseDetail.createDate.substring(
                  8,
                  10,
                )}/${caseDetail.createDate.substring(2, 4)}`
              : ""
          }
        />
        <Box sx={{ flexGrow: 1 }} />
        {/* {!widthAll && <UDTopTools />} */}
        {!widthAll && (
          <>
            {checkShowUpperLowerIcons() && (
              <Stack
                sx={{
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: theme.transBkColor.light,
                  boxShadow: 3,
                  zIndex: 20,
                  ...refineObj1,
                }}
                direction={"row"}
              >
                <Tooltip
                  style={{
                    backgroundColor: curArch === "up" ? "lightgray" : "",
                  }}
                  title="Upper"
                  placement="bottom"
                >
                  <ToggleButton
                    sx={{
                      border: "none",
                      borderRadius: "8px",
                      "&:hover": {
                        borderRadius: "8px",
                      },
                      "&.Mui-selected": {
                        borderRadius: "8px",
                      },
                    }}
                    value="showUpper"
                    size="large"
                    onClick={() => onClickUpperLower(true)}
                  >
                    <ReactSVG
                      src={NewUpperIcon}
                      afterInjection={(svg) => {
                        const path = svg.getElementsByTagName("path")[0]
                        if (curArch === "up") {
                          path?.setAttribute("fill", "#215ECD")
                          path?.setAttribute("fill-opacity", "1")
                        } else {
                          path?.setAttribute("fill", "black")
                          path?.setAttribute("fill-opacity", "0.56")
                        }
                      }}
                    ></ReactSVG>
                  </ToggleButton>
                </Tooltip>
                <Tooltip
                  style={{
                    backgroundColor: curArch === "low" ? "lightgray" : "",
                  }}
                  title="Lower"
                  placement="bottom"
                >
                  <ToggleButton
                    sx={{
                      border: "none",
                      borderRadius: "8px",
                      "&:hover": {
                        borderRadius: "8px",
                      },
                      "&.Mui-selected": {
                        borderRadius: "8px",
                      },
                    }}
                    value="showLower"
                    size="large"
                    onClick={() => onClickUpperLower(false)}
                  >
                    <ReactSVG
                      src={NewLowerIcon}
                      afterInjection={(svg) => {
                        const path = svg.getElementsByTagName("path")[0]
                        if (curArch === "low") {
                          path?.setAttribute("fill", "#215ECD")
                          path?.setAttribute("fill-opacity", "1")
                        } else {
                          path?.setAttribute("fill", "black")
                          path?.setAttribute("fill-opacity", "0.56")
                        }
                      }}
                    ></ReactSVG>
                  </ToggleButton>
                </Tooltip>
                {!isCasePreview && <NewViewButtons />}
              </Stack>
            )}

            <ZoomBar sx={refineObj2} />

            {!isCasePreview && !isCreateNewRefinement && (
              <Stack
                sx={{
                  height: 48,
                  borderRadius: 2,
                  backgroundColor: theme.transBkColor.light,
                  boxShadow: 3,
                }}
                direction={"row"}
              >
                {!openHistorySplitView && <NewToolButtons />}
              </Stack>
            )}
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {/* <WorkflowBtn /> */}
        <CaseModeBtn />
        {/* uassit button */}
        {caseDisposition !== "ATTENTION_NEEDED" && (
          <>
            {/* <Typography variant="body2">View</Typography> */}
            <Switch
              checked={switchViewEdit}
              onChange={() => {
                if (!switchViewEdit) {
                  dispatch(
                    fetchFileList({
                      patientId,
                      caseId,
                      fileNames: FILEZIPS,
                      checkFileUpdates: true,
                    }),
                  ).then((res) => {
                    if (res.payload?.result?.length) {
                      const updatedFiles = res.payload.result
                        .filter((file) => file.attachment_type === "cases")
                        .map((file) => ({
                          name: file.file_name,
                          time: file.updated_date,
                        }))
                      const isCaseUpdated = hasFileHistoryChanged(
                        fileHistory,
                        updatedFiles,
                      )
                      if (isCaseUpdated) {
                        setShowcaseUpdatedModal(isCaseUpdated)
                      } else {
                        switchEditCallBack()
                      }
                    }
                  })
                } else {
                  switchEditCallBack()
                }
              }}
              sx={{ visibility: openHistorySplitView ? "hidden" : "visible" }}
            ></Switch>
            <Typography
              variant="body2"
              color={"white"}
              sx={{
                visibility: openHistorySplitView ? "hidden" : "visible",
                marginLeft: "0px !important",
              }}
            >
              Edit
            </Typography>
          </>
        )}

        <Fab
          variant="extended"
          size="large"
          onClick={handleUassistClick}
          sx={{
            marginRight: "15px",
            zIndex: 1,
            visibility: openHistorySplitView ? "hidden" : "visible",
          }}
          aria-label="uAssist"
          disabled={!isEdit}
        >
          <Typography variant="inherit">{t("button.uassist")}</Typography>
          <Person sx={{ ml: 1 }} color="action" />
        </Fab>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          onClick={() => {
            orderClick(() => {
              dispatch(resetOrder())
              // popup lock-case
              if (orderButtonPopUpTmp.includes(caseDisposition)) {
                setLockType(1)
                setOpenLockPopup(true)
                return
              }
              setisClickOrder(true)
              if (planList.length > 1) {
                setispopupAfterOrder(true)
                setTimeout(() => {
                  navigate(`/order/patient/${patientId}/case/${caseId}/bundle`)
                }, 0)
                return
              }
              if (planList.length === 1) {
                dispatch(setData({ loading: "pending" }))
                setTimeout(() => {
                  const checkIprStr =
                    wasmModule.statusController.checkCollisionWithIPR(
                      NumberSystem,
                    )
                  dispatch(setData({ loading: "succeeded" }))
                  if (checkIprStr !== "") {
                    setCheckIPRobj({
                      show: true,
                      showDetail: false,
                      detail: checkIprStr,
                    })
                    return
                  }
                  navigate(`/order/patient/${patientId}/case/${caseId}/bundle`)
                }, 500)
              }
            })
          }}
          sx={{
            marginRight: "15px",
            zIndex: 1,
            visibility: openHistorySplitView ? "hidden" : "visible",
          }}
          aria-label="order"
          disabled={
            caseDisposition
              ? caseDisposition !== "FROM_UDESIGN" &&
                !orderAbleTmp.includes(caseDisposition)
              : //    ||
                // (caseDisposition === "FROM_UDESIGN" &&
                //   !upperType.includes("R") &&
                //   !lowerType.includes("R") &&
                // !stageDataLen.up &&
                // !stageDataLen.low)
                false
          }
        >
          <Typography variant="inherit">{t("button.order")}</Typography>
          <ShoppingCartIcon sx={{ ml: 1 }} color="background.paper" />
        </Fab>
        <IconButton
          sx={{
            opacity: 0.5,
            backgroundColor: "#E0E0E0",
            height: "48px",
            width: "48px",
          }}
          aria-label="settings"
          onClick={onClickSaveCase}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogBox
        open={noPhotoObj.open}
        title={"Warning"}
        content={<>{`Please check photos`}</>}
        actions={
          <UButton
            onClickHandler={() => {
              setNophotoObj({ open: false, info: "" })
            }}
            variant={"contained"}
            btnText={t("treat.lockPopUp.okbtn")}
          />
        }
      />
      <Dialog open={openUassist}>
        <DialogTitle sx={{ padding: "16px 24px" }}>
          <Typography variant="h6" color={"rgba(0, 0, 0, 0.87)"}>
            uAssist
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ marginTop: "20px" }} variant="body1" color={"#000"}>
            How can uAssist help you with your treatment plan?
          </Typography>
          <Box component={"div"} sx={{ display: "flex", marginTop: "16px" }}>
            <Box width={248} sx={{ marginRight: "16px" }}>
              <Typography
                onClick={() => {
                  dispatch(setUassistRxShow(true))
                  const { normalPosition, xrayPosition } =
                    checkIsThereASpecifiedPhoto(photoTypeJson || {})
                  if (!normalPosition && !xrayPosition) {
                    dispatch(setCurLeftPage("records"))
                  }
                  setOpenUassist(false)
                }}
                variant="h6"
                color={"#FFF"}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "80px",
                  backgroundColor: "#215ECD",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "8px",
                  lineHeight: "32px",
                }}
              >
                Continue My Treatment Plan
              </Typography>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                marginTop={"16px"}
              >
                uAssist to continue your existing treatment plan based on the
                last stage.
              </Typography>
            </Box>
            <Box width={248}>
              <Typography
                variant="h6"
                color={"#FFF"}
                onClick={() => {
                  flushSync(() => setOpenInitialFinal(false))
                  flushSync(() => setOpenGrid(false))
                  showRefinementPage()
                }}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "80px",
                  backgroundColor: "#215ECD",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "8px",
                  lineHeight: "64px",
                }}
              >
                Submit a Refinement
              </Typography>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                marginTop={"16px"}
              >
                Upload new scans for uAssist to plan the refinement.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <UButton
            onClickHandler={() => {
              setOpenUassist(false)
            }}
            variant={"text"}
            btnText={"CANCEL"}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={isDeletePlan}>
        <DialogTitle>
          Choose a single treatment plan prior to submitting
        </DialogTitle>
        <DialogContent>
          Please delete one of the treatment plans by clicking the X next to the
          plan name before submitting.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setisDeletePlan(false)
            }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* {openRefDialog && (
        <CreateRefinement
          onClose={() => {
            setOpenRefDialog(false)
            treatView.Open(false)
            dispatch(setStageShowType("normal"))
            dispatch(setIsCreateNewRefinement(false))
          }}
        ></CreateRefinement>
      )} */}

      <Dialog open={isStartRefinement}>
        <DialogTitle>Start Course Refinement</DialogTitle>
        <DialogContent>
          <Typography sx={{ marginTop: "20px" }} variant="body1" color={"#000"}>
            What type of refinement would you like to start?
          </Typography>
          <Box component={"div"} sx={{ display: "flex", marginTop: "16px" }}>
            <Box width={248} sx={{ marginRight: "16px" }}>
              <Typography
                variant="h6"
                color={"rgba(0, 0, 0, 0.38)"}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "80px",
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "8px",
                  lineHeight: "32px",
                }}
              >
                Self-Planned Refinement
              </Typography>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                marginTop={"16px"}
              >
                I will treatment plan the case myself by opening the
                <Typography
                  variant="body2"
                  color={"rgba(0, 0, 0, 0.60)"}
                  fontWeight={700}
                  display={"contents"}
                >
                  {" "}
                  uDesign desktop application.
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                marginTop={"16px"}
              >
                Self-planned refinements are not available in the cloud yet.
              </Typography>
            </Box>
            <Box width={248}>
              <Typography
                variant="h6"
                color={"#FFF"}
                onClick={() => {
                  flushSync(() => setOpenInitialFinal(false))
                  flushSync(() => setOpenGrid(false))
                  showRefinementPage()
                  setisStartRefinement(false)
                }}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "80px",
                  backgroundColor: "#215ECD",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "8px",
                  lineHeight: "64px",
                }}
              >
                uAssist Refinement
              </Typography>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                marginTop={"16px"}
              >
                uAssist to treatment plan the case following my instructions.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <UButton
            onClickHandler={() => {
              setisStartRefinement(false)
            }}
            variant={"text"}
            btnText={"CANCEL"}
          />
        </DialogActions>
      </Dialog>

      {/* check ipr pop-up */}
      <Dialog open={checkIPRobj.show}>
        <DialogTitle sx={{ padding: "16px 24px" }}>
          <Typography variant="h6" color={"rgba(0, 0, 0, 0.87)"}>
            Collision Warning
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "12px" }}>
          <Typography variant="body1" color={"rgba(0, 0, 0, 1)"}>
            Mid-course collisions need to be resolved.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold" }}
            // marginTop={"16px"}
          >
            Failure to do so may impact treatment outcome.
          </Typography>
          <Box component={"div"} sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body2"
                color={"rgba(0, 0, 0, 0.60)"}
                // marginTop={"16px"}
                sx={{
                  display: checkIPRobj.showDetail ? "" : "none",
                  whiteSpace: "break-spaces",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {checkIPRobj.detail}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "space-between", padding: "8px 14px 8px 24px" }}
        >
          <Box>
            <UButton
              onClickHandler={() => {
                setCheckIPRobj({
                  ...checkIPRobj,
                  showDetail: !checkIPRobj.showDetail,
                })
              }}
              variant={"outlined"}
              btnText={
                checkIPRobj.showDetail ? "Hide Details..." : "Show Details..."
              }
            />
          </Box>

          <Box>
            <UButton
              onClickHandler={() => {
                setCheckIPRobj({ show: false, detail: "", showDetail: false })
                navigate(`/order/patient/${patientId}/case/${caseId}/bundle`)
              }}
              variant={"contained"}
              btnText={"Continue"}
            />
            <UButton
              onClickHandler={() => {
                setCheckIPRobj({ show: false, detail: "", showDetail: false })
              }}
              variant={"text"}
              btnText={"Cancel"}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
