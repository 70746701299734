import {
  Box,
  Button,
  Stack,
  ToggleButton,
  Tooltip,
  useTheme,
} from "@mui/material"
import { LeftPage } from "./LeftPage"
import Filter1Icon from "@mui/icons-material/Filter1"
import Filter2Icon from "@mui/icons-material/Filter2"
import Filter3Icon from "@mui/icons-material/Filter3"
import Filter4Icon from "@mui/icons-material/Filter4"

const CorrectionTool = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        left: 84,
        top: 104,
        borderRadius: "8px",
        backgroundColor: theme.transBkColor?.light,
        maxHeight: `calc(100% - 114px)`,
        boxShadow: 6,
        overflow: "hidden",
        position: "relative",
        zIndex: 1000,
        width: "110px",
      }}
    >
      <Stack spacing={1} direction="row">
        <Tooltip title="Tool1" placement="top">
          <ToggleButton sx={{ border: "none" }} value={"Tool1"}>
            <Filter1Icon
              onClick={() => {
                console.log("Tool1")
              }}
            />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tool2" placement="top">
          <ToggleButton sx={{ border: "none" }} value={"Tool2"}>
            <Filter2Icon
              onClick={() => {
                console.log("Tool2")
              }}
            />
          </ToggleButton>
        </Tooltip>
      </Stack>
      <Stack spacing={1} direction="row">
        <Tooltip title="Tool3" placement="bottom">
          <ToggleButton sx={{ border: "none" }} value={"Tool3"}>
            <Filter3Icon
              onClick={() => {
                console.log("Tool3")
              }}
            />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tool4" placement="bottom">
          <ToggleButton sx={{ border: "none" }} value={"Tool4"}>
            <Filter4Icon
              onClick={() => {
                console.log("Tool4")
              }}
            />
          </ToggleButton>
        </Tooltip>
      </Stack>
    </Box>
  )
}

export default CorrectionTool
