import { sagemakerModule } from "./sagemakerModule";
import { wasmModule } from "../wasm/wasmModule";
import { getAccessToken } from "@/core/config/okta.config"
const {
  VITE_WEBSOCKET_URL
} = import.meta.env

const _sagemakerinit = async (upperArch: Blob, lowerArch: Blob, canvas) => {
  // const writeFileList: Record<string, any> = {};
  // if (upperArch) {
  //   const ab = await upperArch.arrayBuffer();
  //   const up = new Uint8Array(ab);
  //   writeFileList['test/pre/arch_u.stl'] = up;
  // }
  // if (lowerArch) {
  //   const lab = await lowerArch.arrayBuffer();
  //   const low = new Uint8Array(lab);
  //   writeFileList['test/pre/arch_l.stl'] = low;
  // }

  const windowTitle = document.title;

  wasmModule.initWASM(canvas, () => {
    const u = wasmModule.module.allocateUTF8("test/pre/arch_u.stl");
    const l = wasmModule.module.allocateUTF8("test/pre/arch_l.stl");
    wasmModule.module._newpresetup(
      u,
      l,
      wasmModule.module.allocateUTF8(windowTitle)
    );
  });
};

const redoPresetup = async canvas => {
  const windowTitle = document.title;

  wasmModule.initWASM(canvas, () => {
    const u = wasmModule.module.allocateUTF8(
      "test/case/Setting Data0/arch_u.mtc"
    );
    const l = wasmModule.module.allocateUTF8(
      "test/case/Setting Data0/arch_l.mtc"
    );
    wasmModule.module._newpresetup(
      u,
      l,
      wasmModule.module.allocateUTF8(windowTitle)
    );
  });
};

/**
 * param canvas： 绘制canvas的位置
 * param upperArch 上牙stl
 * param lowerArch 下牙stl
 * loadingendcallback: 完成segment(上下颌各一次)
 * donecallback: 完成presetup
 * */
export async function doPresetupWorkflow(
  upperArch: Blob,
  lowerArch: Blob,
  canvas,
  loadingendcallback: () => void,
  donecallback: () => void
) {
  if (wasmModule.module) {
    wasmModule.cancelemloop();
    wasmModule.module._freePreview();
  }
  _sagemakerinit(upperArch, lowerArch, canvas);
  sagemakerModule.setLoadEndCallback(loadingendcallback, donecallback);
  wasmModule.isPresetup = true;
}
export async function redoPresetupFlow(
  canvas,
  loadingendcallback: () => void,
  donecallback: () => void
) {
  if (wasmModule.module) {
    wasmModule.cancelemloop();
    // wasmModule.module._freePreview();
  }
  redoPresetup(canvas);

  sagemakerModule.setLoadEndCallback(loadingendcallback, donecallback);
  wasmModule.isPresetup = true;
}

export async function requestAICloudPresetup(
  upper: boolean,
  lower: boolean,
  orientation: boolean,
  orgId,
  patientId,
  caseId,
):Promise<boolean>{
  let currentReconnect = 0;
  return reconnectWebsocket(upper, lower, orientation, orgId, patientId, caseId,currentReconnect);
}


export async function reconnectWebsocket(
  upper: boolean,
  lower: boolean,
  orientation: boolean,
  orgId,
  patientId,
  caseId,
  reconnectCount,
):Promise<boolean>{
    try{
      const ret = await connectWebsocket(upper, lower, orientation, orgId, patientId, caseId);
      return ret
    }
    catch(error) {
      console.warn(`connectWebsocket error:${error},reconnect...`);
      if(reconnectCount<2){
        const ret = await reconnectWebsocket(upper, lower, orientation, orgId, patientId, caseId,++reconnectCount);
        return ret
      }else{
        return false;
      }
    }
}

export function connectWebsocket(
  upper: boolean,
  lower: boolean,
  orientation: boolean,
  orgId,
  patientId,
  caseId,
):Promise<boolean>{
  const promise = new Promise<boolean>((resolve,reject)=>{
    // const isUD = import.meta.env.VITE_NODE_ENV === '"local"'
    let connect = false

    // const urlType = isUD ? "ai-presetup-for-ud" : "ai-clinical-presetup";
    // const prestupToken = isUD ? getAccessToken() : "4a793be7-51f0-4d51-892f-11022e413e94"
    // const presetupType = isUD ? "presetup_launch_in_storage" : "presetup_launch";
    const urlType = "ai-clinical-presetup";
    const prestupToken = getAccessToken()
    const presetupType = "presetup_launch2";

    let heartbeatTimeout;

    const uploadUrlRq = new WebSocket(
      // VITE_AICLOUD,
      `${VITE_WEBSOCKET_URL}/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/${urlType}`,
      // "wss://apis.dev.udesign.cloud/patient-mgmt/v1/org/1243/patients/10421/txplans/30005/ai-clinical-presetup",
      prestupToken,
    );

    uploadUrlRq.onopen = function () {
      const body = {
        type: presetupType,
        body: {
          jaw_upper: upper,
          jaw_lower: lower,
          orientation: orientation,
          org_id: orgId,
          patient_id: patientId,
          case_id: caseId.toString(),
          token: getAccessToken()
        }
      };
      uploadUrlRq.send(JSON.stringify(body));
      connect = true
      startHeartbeatCheck();
    };
    uploadUrlRq.onclose = function (e) {
      clearTimeout(heartbeatTimeout);
      console.log("AICloud websocket close result:", e);
      if(e.code === 1006 && !connect){
        console.error('无法建立连接,尝试重新连接');
        // 尝试重新连接
        // setTimeout(() => {
        //   connectWebsocket(upper, lower, orientation, orgId, patientId, caseId)
        // }, 1000);
        //throw new Error("network error")
        reject("network error")
      }
    };
    uploadUrlRq.onerror = function (e) {
      console.log("AICloud websocket error:", e);
    }
 
    uploadUrlRq.onmessage =  (msg) => {
      const uploadUrl = JSON.parse(msg.data);
      console.log('presetup:::msg::',msg);
      resetHeartbeatCheck();

      switch (uploadUrl.type) {
        case `response_${presetupType}`:
          if (uploadUrl.body.result === 2) {
            uploadUrlRq.close();
            resolve(true)
            // return true;
          } else if (uploadUrl.body.result === 3) {
            uploadUrlRq.close();
            //return false;
            resolve(false)
          }
          break;
        case "inference":
          break;
        case "response_inference":
          break;
        case "keep_alive":
          break;
        case "result":
          break;
      }
    };

    const startHeartbeatCheck = function () {
      heartbeatTimeout = setTimeout(function() {
        console.log("No heartbeat received, closing connection");
        uploadUrlRq.close();
      }, 60000); 
    }

    const resetHeartbeatCheck = function () {
      clearTimeout(heartbeatTimeout);
      startHeartbeatCheck();
    }
  })
  return promise;
}
